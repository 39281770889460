<template>
    <div class="app-container" v-loading="loading">
        <el-header class="new-header">
            <div class="new-header-left">业务线路列表</div>
            <div class="new-header-right">
                <el-button class="btn" @click="dialogForAddBusinessLine = true" type="primary" plain>
                    新建业务线路
                </el-button>
            </div>
        </el-header>
        <el-form
                :inline="true"
                :model="searchForm"
                ref="searchForm"
                @keyup.enter.native="onSubmit"
        >
            <el-form-item label="业务线路名称">
                <el-input
                        v-model="searchForm.businessLineName"
                        placeholder="业务线路名称"
                        size="small"
                        clearable
                        @clear="searchForm.businessLineName = null"
                ></el-input>
            </el-form-item>
            <el-form-item label="状态" prop="state">
                <el-select
                        v-model="searchForm.state"
                        clearable
                        @clear="searchForm.state = null"
                        placeholder="状态"
                        size="small"
                >
                    <el-option label="启用" :value="1"></el-option>
                    <el-option label="停用" :value="0"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="onSubmit" icon="search" size="small">搜索</el-button>
            </el-form-item>
        </el-form>
        <el-table
                :data="list"
                border
                style="width: 100%"
                ref="list"
                stripe
                size="small"
        >
            <el-table-column
                    prop="businessLineName"
                    label="业务线路名称"
                    align="center"
            ></el-table-column>
            <el-table-column prop="stateName" label="状态" align="center" width="200">
                <template slot-scope="scope">
                    <el-switch
                            v-model="scope.row.state"
                            active-text="启用"
                            inactive-text="停用"
                            :active-value="1"
                            :inactive-value="0"
                            @change="handleChangeBusinessLineState(scope.row)"
                    >
                    </el-switch>
                </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注" align="center"></el-table-column>
            <el-table-column prop="updateTime" label="修改时间" align="center"></el-table-column>
            <el-table-column label="操作" align="center">
                <template slot-scope="scope">
                    <el-tooltip placement="top" content="查看">
                        <el-button
                                type="text"
                                style="color: red"
                                @click="handleViewDetails(scope.row)"
                                size="small"
                        >
                            查看
                        </el-button>
                    </el-tooltip>
                </template>
            </el-table-column>
        </el-table>
        <div class="pagination-block">
            <el-pagination
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="pageVo.current"
                    :page-sizes="[2, 10, 20, 50, 100]"
                    :page-size="pageVo.size"
                    layout="total, sizes, prev, pager, next, jumper"
                    :total="totalCount"
            >
            </el-pagination>
        </div>
        <el-dialog title="新建业务线路" :visible.sync="dialogForAddBusinessLine">
            <el-form
                    ref="addForm"
                    :model="addForm"
                    label-width="120px"
                    :rules="rules"
            >
                <el-row type="flex" justify="center">
                    <el-col :span="12">
                        <el-form-item label="业务线路名称:" prop="businessLineName">
                            <el-input
                                    v-model="addForm.businessLineName"
                                    placeholder="请输入业务线路名称"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="状态:" prop="state">
                            <el-select v-model="addForm.state" placeholder="状态">
                                <el-option label="启用" :value="1"></el-option>
                                <el-option label="停用" :value="0"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="备注:" prop="remark">
                            <el-input
                                    v-model="addForm.remark"
                                    type="textarea"
                                    placeholder="请输入备注"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <div slot="footer">
                <el-button type="info" @click="handleCancelCreateBusinessLine">取消</el-button>
                <el-button type="primary" @click="handleCreateBusinessLine">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    import {
        createBusinessLine,
        editApplyState,
        getApplyListByPage,
        listBusinessLineByPage,
        updateBusinessLine,
		updateBusinessLineState
    } from "../../api";

    export default {
        name: "index.vue",
        data() {
            return {
                dialogForAddBusinessLine: false,
                dialogForModifyApply: false,
                searchForm: {
                    adminId: this.$store.getters.adminId,
                    state: null,
                    businessLineName: null,
                },
                addForm: {
                    businessLineName: "",
                    remark: "",
                    state: 1,
                    adminId: this.$store.getters.adminId,
                },
                list: [],
                pageVo: {
                    current: 1,
                    size: 10,
                },
                totalCount: 0,
                loading: false,
                lineList: [],
                rules: {
                    businessLineName: [
                        {required: true, message: "请输入业务线路名称", trigger: "blur"},
                    ],
                },
            }
        },
        created() {
            this.loadBusinessLineList()
        },
        methods: {
            handleViewDetails(row){
                if(row.businessLineId){
                    this.$router.push({
                        name:'business-line-details',
                        query:{
                            id:row.businessLineId
                        }
                    })
                }
            },
            async handleChangeBusinessLineState(row) {
                let param = {
                    businessLineId: row.businessLineId,
                    state: row.state,
                    adminId: this.$store.getters.adminId,
                };
                const data = await updateBusinessLineState(param)
                if (data) {
                    let message =
                        row.state === 1
                            ? `启用应用${row.businessLineName}成功`
                            : `停用应用${row.businessLineName}成功`;
                    this.$notify({
                        type: "success",
                        title: "成功",
                        message: message,
                    });
                }
            },
            async handleCreateBusinessLine() {
                const params = {
                    ...this.addForm
                }
                const data = await createBusinessLine(params)
                if (data) {
                    this.$notify({title: '成功', message: '创建业务线路成功', type: 'success'})
                    this.dialogForAddBusinessLine = false
                    this.loadBusinessLineList()
                }
            },
            handleCancelCreateBusinessLine() {
                this.addForm.businessLineName = ''
                this.addForm.remark = ''
                this.dialogForAddBusinessLine = false
            },
            onSubmit() {
                this.pageVo.current = 1;
                this.loadBusinessLineList();
            },
            handleSizeChange(val) {
                this.pageVo.size = val;
                this.loadBusinessLineList();
            },
            handleCurrentChange(val) {
                this.pageVo.current = val;
                this.loadBusinessLineList();
            },
            loadBusinessLineList() {
                let params = {
                    ...this.pageVo,
                    params: this.searchForm,
                };
                this.loading = true;
                listBusinessLineByPage(params).then((data) => {
                    this.list = data.records;
                    this.totalCount = data.total;
                    this.loading = false;
                });
            }
        }
    }
</script>

<style scoped>

</style>
